<template>
  <div @click="model=name" class="cursor-pointer mx-4">
    <div
      class="flex flex-col rounded-lg sm:shadow-lg sm:w-64 sm:h-64 border-background-grey border-2 sm:hover:border-primary sm:checkbox-card"
    >
      <div class="flex cursor-pointer sm:justify-end sm:p-3">
        <label :for="cbId" class="font-normal base-radio-button-container">
          <div class="sm:hidden block text-center text-md ml-1 font-medium">
            {{ name }}
          </div>
          <input
  :id="cbId"
  class="form-check-input"
  type="radio"
  :disabled="disabled"
  :value="label"
  v-model="model"
/>
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="sm:block hidden self-center m-8">
        <img
        height="120"
        width="120"
          src="img/estimate/time.svg"
          class="filter-pink"
          alt=""
        />
        <div class="text-center text-lg font-medium">{{ name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: [String, Number],
    disabled: Boolean,
    value: [String, Boolean],
    name: String
  },
  data() {
    return {
      cbId: ""
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  }
};
</script>

<style lang="css" scoped>
/*! CSS Used from: Embedded */
.filter-pink{
    filter: invert(65%) sepia(96%) saturate(2603%) hue-rotate(319deg) brightness(99%) contrast(104%);
}

:-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
::-moz-placeholder {
  color: #021926;
  margin: 2rem;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.border-background-grey {
  border-color: #fbfbfc;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-2 {
  border-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.m-8 {
  margin: 2rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
}
@media (min-width: 576px) {
  .sm\:block {
    display: block;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:h-64 {
    height: 16rem;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
  }
  .sm\:w-64 {
    width: 16rem;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.base-radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.base-radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/*! CSS Used from: Embedded */
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.border-background-grey {
  border-color: #fbfbfc;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-2 {
  border-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.m-8 {
  margin: 2rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
}
@media (min-width: 576px) {
  .sm\:block {
    display: block;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:h-64 {
    height: 16rem;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
  }
  .sm\:w-64 {
    width: 16rem;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.base-radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.base-radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.base-radio-button-container input:checked ~ .checkmark:after {
  display: block;
}
.base-radio-button-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff6b6b;
}
:-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
::-moz-placeholder {
  color: #021926;
  margin: 2rem;
}
div,
span {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
::-moz-selection {
  background-color: #338fff !important;
  color: #fff !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
/*! CSS Used from: Embedded */
div,
span {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
</style>
