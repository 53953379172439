<template>
  <div>
    <footer class="footer">
      <div class="footer__content">
        <div class="links-menu">
          <a
            href="/"
            aria-current="page"
            class="no-border mb-4 flex items-center -mt-4"
            ><img
              src="@/assets/brand/logo-dark.png"
              alt="Company Logo"
              class="logo-footer"
          /></a>
          <div class="footer__link">Contact</div>
          <a
            :href="'mailto:'+$company.contact.email"
            class="text-primary hover:text-primary-dark"
          >
            {{$company.contact.email}}
          </a>
          <br>
          <div class="footer__link">Phone</div>
          <a
            :href="'tel:'+$company.contact.countryCode+$company.contact.phone"
            class="text-primary hover:text-primary-dark"
          >
           {{$company.contact.countryCode}} {{$company.contact.phone}}
          </a>
        </div>
        <div class="flex justify-center">
          <div class="links-menu">
            <div class="footer__link--menu">MENU</div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Home' }">Home</FootLink>
            </div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Careers' }">Careers</FootLink>
            </div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Contact' }">Contact</FootLink>
            </div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Privacy' }">Privacy Policy</FootLink>
            </div>
          </div>
          <div class="links-menu">
            <div class="footer__link--menu">SERVICES</div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Projects' }">Projects</FootLink>
            </div>
            <div class="pb-4">
              <FootLink :to="{ name: 'Process' }">Process</FootLink>
            </div>

            <div class="pb-4">
              <FootLink :to="{ name: 'Estimate' }">Request Estimation</FootLink>
            </div>
          </div>
        </div>
        <div class="links-menu">
          <a
            :href="$company.map"
            target="_blank"
            rel="noopener"
            ><address class="address">
              {{$company.name}}
              <br />
              {{$company.contact.street}},
              <br />
              {{$company.contact.landmark}},
              <br />
              {{$company.contact.city}}, {{$company.contact.pin}},
              <br />
              {{$company.contact.country}}
            </address></a
          >
          <div class="social-links mt-4">
            <a
              :href="$company.links.github"
              target="_blank"
              rel="noopener"
              aria-label="Github Link"
              class="bg-grey-smooth pt-3 px-2 mr-1 rounded hover:bg-primary"
            >
             <github-icon class="feather feather-github"></github-icon>
           </a>
            <a
              :href="$company.links.facebook"
              target="_blank"
              rel="noopener"
              aria-label="Facebook Link"
              class="bg-grey-smooth pt-3 px-2 mr-1 rounded hover:bg-primary"
            >
            <facebook-icon class="feather feather-facebook"></facebook-icon>
          </a>
            <a
              :href="$company.links.instagram"
              target="_blank"
              rel="noopener"
              aria-label="Instagram Link"
              class="bg-grey-smooth pt-3 px-2 mr-1 rounded hover:bg-primary"
              >
              <instagram-icon class="feather feather-Instagram"></instagram-icon>
            </a>
            <a
              :href="$company.links.twitter"
              target="_blank"
              rel="noopener"
              aria-label="Twitter Link"
              class="bg-grey-smooth pt-3 px-2 mr-1 rounded hover:bg-primary"
            >
            <twitter-icon class="feather feather-twitter"></twitter-icon>
          </a>
            <a
              :href="$company.links.linkedin"
              target="_blank"
              rel="noopener"
              aria-label="Linkedin Link"
              class="bg-grey-smooth pt-3 px-2 mr-1 rounded hover:bg-primary"
              >
              <linkedin-icon class="feather feather-Linkedin"></linkedin-icon>
            </a>
          </div>
        </div>
        <div class="footer-background"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import { FootLink } from "@/components";
import { FacebookIcon, GithubIcon, InstagramIcon, TwitterIcon, LinkedinIcon } from 'vue-feather-icons';

export default {
  components: {
    FootLink,
    FacebookIcon,
    GithubIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon
  }
};
</script>

<style lang="css" scoped>
a {
  background-color: transparent;
}
img {
  border-style: none;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
img {
  border-style: solid;
}
img {
  max-width: 100%;
  height: auto;
}
.bg-grey-smooth {
  background-color: #bfc4c8;
}
.hover\:bg-primary:hover {
  background-color: #ff6b6b;
}
.rounded {
  border-radius: 0.25rem;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.-mt-4 {
  margin-top: -1rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.text-primary {
  color: #ff6b6b;
}
.hover\:text-primary-dark:hover {
  color: #ff5064;
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
body a:not(.navbar-link) {
  color: #ff6b6b;
}
body a:not(.navbar-link):active,
body a:not(.navbar-link):hover,
body a:not(.navbar-link):visited {
  color: #ff5064;
}
body a,
body a.active {
  text-decoration: none;
}
body a.active {
  padding-bottom: 3px;
  border-bottom-width: 2px;
  border-color: #ff6b6b;
}
body a.active.no-border {
  border-bottom: 0;
}
.footer__link {
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: rgba(2, 25, 38, 0.5);
}
.footer__link:hover {
  color: #ff6b6b;
  cursor: pointer;
}
.address {
  transition: all 0.2s;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  color: rgba(2, 25, 38, 0.5);
  font-size: 1rem;
  font-weight: 500;
}
.address:hover {
  color: #ff6b6b;
  cursor: pointer;
}
.footer {
  background-color: #fff;
  min-height: 360px;
  position: relative;
}
.footer-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 50px);
  opacity: 0.7;
  z-index: 1;
  display: block;
}
.footer__link--menu,
.footer__link {
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: rgba(2, 25, 38, 0.5);
}
.footer__link--menu:hover,
.footer__link:hover {
  color: #ff6b6b;
  cursor: pointer;
}
.footer__link--menu {
  position: relative;
  padding-left: 22px;
  font-weight: 500;
  padding-bottom: 20px;
}
.footer__link--menu:before {
  position: absolute;
  left: 0;
  top: 12px;
  content: " ";
  width: 18px;
  height: 3px;
  display: block;
  background-color: #ff6b6b;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: 2;
  padding: 90px 40px 40px;
}
@media (min-width: 992px) {
  .footer__content {
    padding: 90px 60px 40px;
  }
}
@media (min-width: 1200px) {
  .footer__content {
    padding: 90px 120px 40px;
  }
}
.links-menu {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  z-index: 10;
  margin-left: 0.5rem;
  margin-bottom: 2.5rem;
  min-width: 150px;
}
@media (min-width: 360px) {
  .links-menu {
    min-width: 160px;
  }
}
@media (min-width: 992px) {
  .links-menu {
    min-width: 200px;
    margin-left: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .links-menu {
    min-width: 250px;
  }
}
.logo-footer {
  max-height: 40px;
  opacity: 0.7;
}
.social-links circle,
.social-links line,
.social-links path,
.social-links rect {
  color: #fff !important;
}
/*! CSS Used from: Embedded */
div,
a {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
</style>
