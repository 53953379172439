<template>
  <router-link
    :to="to"
    class="navbar-link"
    @click.native.stop="closeNav()"
    exact
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "nav-link",
  inject: ["closeNavbar"],
  props: {
    to: {
      type: [String, Object],
      default: undefined
    }
  },
  methods: {
    closeNav() {
      if (this.closeNavbar) {
        this.closeNavbar();
      }
    }
  }
};
</script>
