<template>
  <div class="sm:block hidden hamburger-menu menu-icon" :class="[{ black: black }, { animation: toggled }, { fade: !toggled }, ]">
    <div id="bar1" class="bar"></div>
    <div id="bar2" class="bar"></div>
    <div id="bar3" class="bar"></div>
  </div>
</template>
<script>
export default {
  name: 'navbar-toggle-button',
  props: {
    toggled:{
      type:Boolean,
      default:false
    },
    black:{
      type:Boolean,
      default:true
    }
  }
};
</script>
