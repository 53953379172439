<template>
  <div class="step-wrapper" :class="[{'flex my-5':!no,'text-secondary-dark relative my-8':no}]">
    <div v-if="!no" class="mt-1 mr-5">
      <div class="circle circle-result"></div>
    </div>
    <h2 v-if="no" class="background-step h1">{{no}}</h2>
    <div class="leading-normal font-normal mb-8">
    <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    no:String
  }
}
</script>

<style lang="css" scoped>
</style>
