import { render, staticRenderFns } from "./FootLink.vue?vue&type=template&id=5c9ae786&"
import script from "./FootLink.vue?vue&type=script&lang=js&"
export * from "./FootLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports