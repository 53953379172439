<template>
  <Navbar :transparent="transparent" :color-on-scroll="colorOnScroll">
    <router-link :to="{ name: 'Home' }" class="no-border nuxt-link-active" slot="logo">
      <nav-logo></nav-logo>
    </router-link>
    <template slot="navbar-menu">
      <div class="navbar-link__wrapper">
        <nav-link :to="{ name: 'Home' }">Home</nav-link>
      </div>
      <div class="navbar-link__wrapper">
        <nav-link :to="{ name: 'Projects' }">Projects</nav-link>
      </div>
      <div class="navbar-link__wrapper">
        <nav-link :to="{ name: 'Careers' }">Careers</nav-link>
      </div>
      <div class="navbar-link__wrapper">
        <nav-link :to="{ name: 'Contact' }">Contact</nav-link>
      </div>
    </template>
    <a @click="navigate()" class="mr-2 estimate-button" slot="after-menu">
      <button class="btn text-xs primary outline btn-sm" >
        Request Estimation
      </button>
    </a>
  </Navbar>
</template>

<script>
import { Navbar, NavLink } from "@/components";

import NavLogo from "./NavLogo";

export default {
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    NavLink,
    Navbar,
    NavLogo
  },
  methods:{
    navigate(){
      if (this.$router.currentRoute.name !== "Estimate") {
        this.$router.push('/estimate-project')
      }
    }
  }
};
</script>
