<template>
  <router-link :to="{ name: 'ProjectDetails', params: {project:name} }">
    <div class="fade-in-bottom">
      <div class="md:m-10 mx-4 my-10">
        <div
          id="project-5"
          class="card flex md:flex-row flex-col items-end cursor-pointer"
        >
          <img
            :alt="name"
            class="project__image z-40 lazyloaded"
            :src="image"
          />
          <div
            class="project__card flex -mt-2 md:-ml-1 md:rounded-r rounded-b border"
            :style="`background-image:linear-gradient(${color})`"
          >
            <div class="w-full relative ml-8">
              <div class="h-full flex flex-col justify-between relative z-20">
                <div>
                  <div
                    class="text-sm font-medium leading-relaxed tracking-medium text-white mt-10"
                  >
                    {{type}}
                  </div>
                  <div
                    class="text-white project__title mb-4 text-3xl font-bold mt-1"
                  >
                    <img
                      :alt="name"
                      class=" lazyloaded"
                      :src="logo"
                    />
                  </div>
                  <p
                    class="text-sm tracking-medium leading-normal tracking-wide text-cards-content mr-16 pr-4 font-base"
                  >
                    {{description}}
                  </p>
                </div>
                <div class="flex flex-col link mb-10">
                  <div
                    class="text-lg font-medium leading-normal tracking-wide text-white"
                  >
                    View case
                    <i class="fas fa-arrow-circle-right text-white font-extrabold"></i>
                  </div>
                  <div class="link__underline mt-1"></div>
                </div>
              </div>
              <div>
                <!---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
    props: {
  type: {
    type: String,
    default: 'UnSpecfied'
  },
  description:{
    type: String,
    default: 'UnSpecfied'
  },
  image:{
    type: String,
    default: 'UnSpecfied'
  },
  logo:{
    type: String,
  },
  extra:{
    type:String
  },
  name:{
    type:String
  },
  color:{
    type:String,
  }
}
};
</script>

<style lang="css" scoped></style>
