<template>
  <div class="flex flex-col items-center my-2 w-130 input-wrapper">
    <i class="absolute self-start icon-input-person" :class="icon"></i>
    <div class="w-full">
      <input
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="input input-rounded"
        :class="{ 'has-error': error }"
      />
      <span
        v-if="error"
        class="self-start text-primary text-xs pt-1 text-primary"
        :class="{ error: error }"
        >{{ error }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: String,
    value: {
      type: [String, Number],
      default: ""
    },
    icon:String,
    required: Boolean
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    }
  }
};
</script>

<style lang="css" scoped></style>
