<template>
  <div class="circle-wrapper relative">
    <div class="circle">
      <h5 class="circle-content">{{no}}</h5>
    </div>
    <h5 class="circle-name"><slot></slot></h5>
    <div class="circle-connect"></div>
  </div>
</template>

<script>
export default {
    props: {
      no:String
    }
};
</script>

<style></style>
