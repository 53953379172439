import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/scss/index.scss";
import {COMPANY} from "@/docu.config.js";

Vue.config.productionTip = false;
Vue.prototype.$company = COMPANY;

document.dispatchEvent(new Event('render-ready'))

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
