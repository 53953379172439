import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Home from '../components/HelloWorld.vue'
import MainNavbar from '../layout/MainNavbar.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    components: {default:() => import("../views/Home.vue"),header:MainNavbar},
    props:{header:{colorOnScroll:450}}
  },
  {
    path: "/estimate-project",
    name: "Estimate",
    components: {default:() => import("../views/Estimate.vue"),header:MainNavbar},
  },
  {
    path: "/projects",
    name: "Projects",
    components: {default:() => import("../views/Projects.vue"),header:MainNavbar},
  },
  {
    path: "/projects/:project",
    name: "ProjectDetails",
    components: {default:() => import("../views/Project.vue"),header:MainNavbar},
  },
  {
    path: "/contact",
    name: "Contact",
    components: {default:() => import("../views/Contact.vue"),header:MainNavbar},
  },
  {
    path: "/careers",
    name: "Careers",
    components: {default:() => import("../views/Career.vue"),header:MainNavbar},
  },
  {
    path: "/careers/:job",
    name: "Details",
    components: {default:() => import("../views/JobDetails.vue"),header:MainNavbar},
  },
  {
    path: "/process",
    name: "Process",
    components: {default:() => import("../views/Process.vue"),header:MainNavbar},
  },
  {
    path: "/privacy",
    name: "Privacy",
    components: {default:() => import("../views/Privacy.vue")},
  },
  {
    path: "/terms",
    name: "terms",
    components: {default:() => import("../views/Terms.vue")},
  },
  {
    path: "/refund",
    name: "refund",
    components: {default:() => import("../views/Refund.vue")},
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: to => {
if (to.hash) {
  return { selector: to.hash };
} else {
  return { x: 0, y: 0 };
}
}
});

export default router
