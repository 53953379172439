<template>
  <div @click="model=!checked" class="cursor-pointer mx-4">
    <div
      class="sm:flex hidden flex-col rounded-lg shadow-lg w-64 h-64 border-background-grey border-2 hover:border-primary checkbox-card"
    >
      <div class="rounded-lg base-checkbox-wrapper">
        <label :for="cbId" class="base-checkbox-container"
          >      <input
                  :id="cbId"
                  type="checkbox"
                  ref="input"
                  :disabled="disabled"
                  v-model="model"
                />
          <span
            role="checkbox"
            class="border border-grey-border rounded checkmark"
          ></span
        ></label>
      </div>
      <div class="self-center m-8">
        <slot></slot>
        <div class="text-center text-lg font-medium">{{ name }}</div>
      </div>
    </div>
    <div class="sm:hidden flex m-2 ml-6 no-wrap">
      <div class="rounded-lg base-checkbox-wrapper">
      <label :for="cbId" class="form-check-label">
        <input
          :id="cbId"
          class="form-check-input"
          ref="input"
          type="checkbox"
          :disabled="disabled"
          v-model="model"
        />
        <span
          role="checkbox"
          class="border border-grey-border rounded checkmark"
        ></span
      >
      </label>
      </div>
      <div class="text-center text-md ml-4 font-medium">{{ name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'checked'
  },
  props: {
    checked: [Array, Boolean],
    disabled: Boolean,
    hasError: Boolean,
    name: String,
  },
  data() {
    return {
      cbId: '',
      touched: false
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      }
    }
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  }
};
</script>

<style lang="css" scoped>
.base-checkbox-wrapper {
  margin: 1.125rem;
}
.checkbox-card {
  transition: 0.5s;
}
:-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
::-moz-placeholder {
  color: #021926;
  margin: 2rem;
}
@media (max-width: 1024px) {
  .checkbox-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.no-wrap .base-checkbox-wrapper {
  margin: 0;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.border-grey-border {
  border-color: #d8d8d8;
}
.border-background-grey {
  border-color: #fbfbfc;
}
.hover\:border-primary:hover {
  border-color: #ff6b6b;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-2 {
  border-width: 2px;
}
.border {
  border-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.font-medium {
  font-weight: 500;
}
.h-64 {
  height: 16rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-8 {
  margin: 2rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
}
.w-64 {
  width: 16rem;
}
@media (min-width: 576px) {
  .sm\:hidden {
    display: none;
  }
  .sm\:flex {
    display: flex;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
/*! CSS Used from: Embedded */
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.border-grey-border {
  border-color: #d8d8d8;
}
.border-background-grey {
  border-color: #fbfbfc;
}
.hover\:border-primary:hover {
  border-color: #ff6b6b;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-2 {
  border-width: 2px;
}
.border {
  border-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.font-medium {
  font-weight: 500;
}
.h-64 {
  height: 16rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-8 {
  margin: 2rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
}
.w-64 {
  width: 16rem;
}
@media (min-width: 576px) {
  .sm\:hidden {
    display: none;
  }
  .sm\:flex {
    display: flex;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.base-checkbox-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: right;
}
.base-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  float: right;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
}
.base-checkbox-container input:checked ~ .checkmark {
  background-color: #ff6b6b;
  border-width: 1px;
  border-color: #ff6b6b;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.base-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.base-checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 10px;
  height: 16px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.base-checkbox-wrapper {
  margin: 1.125rem;
}
.checkbox-card {
  transition: 0.5s;
}
:-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
::-moz-placeholder {
  color: #021926;
  margin: 2rem;
}
@media (max-width: 1024px) {
  .checkbox-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.no-wrap .base-checkbox-wrapper {
  margin: 0;
}
div,
span {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
::-moz-selection {
  background-color: #338fff !important;
  color: #fff !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
/*! CSS Used from: Embedded */
div,
span {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
</style>
