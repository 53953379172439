<template>
  <div class="sm:hidden flex justify-around py-2 mobile__navbar border-t">
    <div>
      <router-link :to="{ name: 'Home' }" class="flex flex-col items-center justify-center" exact>
        <home-icon class="mb-2 text-primary feather feather-home"></home-icon>
        <div class="block text-xs md:pl-3 font-medium">
          Home
        </div>
      </router-link>
    </div>
    <div>
      <router-link :to="{ name: 'Projects' }" class="flex flex-col items-center justify-center" exact>
        <monitor-icon class="mb-2 text-primary feather feather-monitor"></monitor-icon>
        <div class="block text-xs md:pl-3 font-medium">
          Projects
        </div>
      </router-link>
    </div>
    <div>
      <router-link :to="{ name: 'Careers' }" class="flex flex-col items-center justify-center" exact>
        <user-plus-icon class="mb-2 text-primary feather feather-folder"></user-plus-icon>
        <div class="block text-xs md:pl-3 font-medium">
          Careers
        </div>
      </router-link>
    </div>
    <div>
      <router-link :to="{ name: 'Contact' }" class="flex flex-col items-center justify-center" exact>
        <phone-icon class="mb-2 text-primary feather feather-phone"></phone-icon>
        <div class="block text-xs md:pl-3 font-medium">
          Contact
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { HomeIcon, MonitorIcon, PhoneIcon, UserPlusIcon  } from 'vue-feather-icons';

export default {
  components: {
    HomeIcon,
    MonitorIcon,
    PhoneIcon,
    UserPlusIcon
  }
};
</script>

<style lang="css" scoped></style>
