<template>
  <router-link :to="{ name: 'Details', params: {job:link} }" class="flex flex-col bg-white rounded-lg px-10 py-8 mx-2 my-6 md:mx-8 career-card">
    <div class="text-primary text-lg m-2">{{title}}</div>
    <div class="m-2">
      {{description}}
    </div>
    <div class="salary-value m-2"></div>
    <div class="text-secondary-dark font-medium m-2">{{level}} level</div>
    <div class="text-secondary-dark font-medium m-2 text-xs">
       {{person}} person
    </div>
    <a class="ml-2">
      See details
    </a></router-link>
</template>

<script>
export default {
  props:{
    person:String,
    description:String,
    level:String,
    title:String,
    link:String
  }
}
</script>

<style lang="css" scoped>
</style>
