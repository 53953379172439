<template>
  <nav class="navbar" :class="classes" style="">
    <div class="navbar__content">
      <div class="logo__container">
          <slot name="logo"></slot>
      </div>
      <div
        class="nav__links open"
        :class="[{ animate: showMenu }, { fade: !showMenu }]"
      >
        <slot name="before-menu"></slot>
        <div class="nav-links__inner">
          <slot name="navbar-menu" v-bind="slotData"></slot>
        </div>
        <slot name="after-menu"></slot>
      </div>
      <navbar-toggle-button
        :toggled="showMenu"
        @click.native.stop="toggle"
      ></navbar-toggle-button>
    </div>
  </nav>
</template>

<script>
import NavbarToggleButton from "./NavbarToggleButton";

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

export default {
  components: {
    NavbarToggleButton
  },
  props: {
    colorOnScroll: {
      type: Number,
      default: 0
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      closeNavbar: this.close,
      isWhite: ()=>{return this.isWhite}
    };
  },
  data() {
    return {
      showMenu: false,
      extraNavClasses: '',
      currentScrollValue: 0
    };
  },
  methods: {
    toggle() {
      this.setNav(!this.showMenu);
    },
    setNav(value) {
      // let htmlClasses = document.querySelector('html').classList;
      // if (value) {
      //   htmlClasses.add('nav-open');
      // } else {
      //   htmlClasses.remove('nav-open');
      // }
      // let isOpen = htmlClasses.contains('nav-open');
      // let eventToTrigger = isOpen ? 'open' : 'close';
      this.showMenu = value;
      // this.$emit(eventToTrigger);
    },
    close() {
      if (this.showMenu) {
        this.setNav(false);
      }
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.currentScrollValue = scrollValue;
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    }
  },
  computed: {
    isWhite(){
      return !this.transparent && this.colorOnScroll === 0 || this.showMenu || !this.colorOnScrollTransparent ;
    },
    colorOnScrollTransparent(){
      return this.colorOnScroll && this.currentScrollValue < this.colorOnScroll;
    },
    classes() {
      return [
        { "navbar-transparent": this.transparent || !this.showMenu || this.colorOnScrollTransparent },
        { "navbar-white": this.isWhite},
      ];
    },
    slotData() {
      return {
        close: this.close
      };
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style lang="css" scoped>
a {
  background-color: transparent;
}
img {
  border-style: none;
}
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  -webkit-appearance: button;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
*,
:after,
:before {
  box-sizing: inherit;
}
button {
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
ul {
  margin: 0;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
img {
  border-style: solid;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  cursor: pointer;
}
.hidden {
  display: none;
}
.mr-2 {
  margin-right: 0.5rem;
}
.text-xs {
  font-size: 0.75rem;
}
@media (min-width: 576px) {
  .sm\:block {
    display: block;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
body a:not(.navbar-link) {
  color: #ff6b6b;
}
body a:not(.navbar-link):active,
body a:not(.navbar-link):hover,
body a:not(.navbar-link):visited {
  color: #ff5064;
}
body a,
body a.active {
  text-decoration: none;
}
body a.active {
  padding-bottom: 3px;
  border-bottom-width: 2px;
  border-color: #ff6b6b;
}
body a.active.no-border {
  border-bottom: 0;
}
body :not(li) > ul {
  list-style: none;
  padding: 0;
  position: relative;
  margin-left: 15px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.btn {
  transition: all 0.2s;
  font-weight: 600;
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  border-radius: 9999px;
  padding: 0.75rem 2.5rem;
  line-height: 1.5;
  border-width: 1px;
}
.btn:focus {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  outline: 0;
}
.btn.outline {
  background-color: transparent;
}
.btn.btn-sm {
  padding: 0.5rem 1.25rem;
}
.btn.primary {
  background-color: #ff6b6b;
  border-color: #ff6b6b;
  color: #fff;
}
.btn.primary:active,
.btn.primary:hover {
  background-color: #ff5064;
  border-color: #ff5064;
}
.btn.primary:active {
  color: #fff;
}
.btn.primary.outline {
  background-color: transparent;
}
.btn.primary.outline:hover {
  background-color: #ff6b6b;
  border-color: #ff5064;
  color: #fff;
}
.btn.primary.outline:active {
  background-color: #ff5064;
  border-color: #ff5064;
  color: #fff;
}
.navbar.navbar-white .btn,
.navbar.navbar-white .navbar-link {
  color: #0b2438;
}
.nav__links {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 71px;
}
.nav__links .nav-links__inner {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}
.nav__links .navbar-link__wrapper {
  padding: 1.5rem 0;
}
.nav__links .navbar-link {
  margin-right: 0;
}
.nav__links.open {
  background-color: #fff;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  opacity: 1;
  display: flex;
  height: 35rem;
}
@media (min-width: 992px) {
  .nav__links.open {
    box-shadow: none;
    height: 0;
  }
}
@media (min-width: 992px) {
  .nav__links {
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    position: relative;
    top: 0;
  }
  .nav__links .nav-links__inner {
    flex-direction: row;
  }
  .nav__links .navbar-link__wrapper {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.75rem;
  }
  .nav__links .navbar-link {
    margin-right: 20px;
  }
}
.navbar-link {
  color: #fff;
  cursor: pointer;
  margin-right: 30px;
}
.navbar-link:hover {
  color: #ff6b6b;
}
.navbar-link__wrapper {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
}
@media (min-width: 992px) {
  .menu-icon {
    display: none;
  }
}
.navbar.navbar-white {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.hamburger-menu {
  width: 24px;
  height: 17px;
  cursor: pointer;
}
.bar {
  background-color: #fff;
  height: 3px;
  width: 100%;
  display: block;
  transition: 0.3s ease;
}
.animation #bar1 {
  -webkit-transform: translateY(4px) rotate(-45deg);
  transform: translateY(4px) rotate(-45deg);
}
.animation #bar3 {
  -webkit-transform: translateY(-3px) rotate(45deg);
  transform: translateY(-3px) rotate(45deg);
}
.animation #bar2 {
  opacity: 0;
}
#bar1 {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
#bar3 {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}
.black .bar {
  background-color: #000;
}
.navbar {
  position: fixed;
  background: transparent;
  width: 100%;
  z-index: 100;
}
.logo__container {
  min-width: 100px;
  max-height: 40px;
}
.logo {
  max-height: 30px;
  max-width: 130px;
}
.navbar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  z-index: 2;
}
@media (min-width: 992px) {
  .navbar__content {
    padding: 20px 60px;
  }
}
@media (min-width: 1200px) {
  .navbar__content {
    padding: 20px 120px;
  }
}
.animate {
  -webkit-animation: anim 0.3s ease-out;
  animation: anim 0.3s ease-out;
}
/*! CSS Used from: Embedded */
div,
a {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
/*! CSS Used keyframes */
@-webkit-keyframes anim {
  0% {
    -webkit-transform: translateY(-15rem);
    transform: translateY(-15rem);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes anim {
  0% {
    -webkit-transform: translateY(-15rem);
    transform: translateY(-15rem);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
