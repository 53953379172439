<template>
  <div>
    <img
      v-if="isWhite()"
      src="@/assets/brand/logo-dark.png"
      alt="Navbar logo"
      class="logo"
    />
    <img
      v-if="!isWhite()"
      src="@/assets/brand/logo.png"
      alt="Navbar logo"
      class="logo"
    />
  </div>
</template>

<script>
export default {
  inject:['isWhite'],
}
</script>

<style lang="css" scoped>
</style>
