<template>
  <div>
  <div class="technology-wrapper py-5 mb-5">
    <div class="flex flex-col xl:flex-row">
      <div class="technology-image-wrapper mx-5">
        <div class="flex justify-center items-center h-full">
          <img
            :src="image"
            alt="Technology image"
            class="technology__icon"
          />
        </div>
      </div>
      <div class="flex flex-col px-5">
        <div
          class="font-semibold text-xl text-secondary-dark mb-3 mt-2"
        >
          {{techName}}
        </div>
        <div class="font-light text-sm md:text-base">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props:{
    image:String,
    techName:String,
  }
}
</script>

<style lang="css" scoped>
</style>
