<template>
  <div>
    <label :for="cbId" class="font-normal base-radio-button-container">
      <slot></slot>
      <input
  :id="cbId"
  type="radio"
  :disabled="disabled"
  :value="label"
  v-model="model"
/>
      <input type="radio" name="radio" value="Under $10,000"/>
      <span class="checkmark"></span
    ></label>
  </div>
</template>

<script>
export default {
  props: {
  label: [String, Number],
  disabled: Boolean,
  value: [String, Boolean]
},
data() {
  return {
    cbId: ''
  };
},
computed: {
  model: {
    get() {
      return this.value;
    },
    set(value) {
      this.$emit('input', value);
    }
  },
},
created() {
  this.cbId = Math.random()
    .toString(16)
    .slice(2);
}
}
</script>

<style lang="css" scoped>
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
input::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
input::-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.font-normal {
  font-weight: 400;
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.base-radio-button-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.base-radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.base-radio-button-container input:checked ~ .checkmark:after {
  display: block;
}
.base-radio-button-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff6b6b;
}
::-webkit-input-placeholder {
  color: #021926;
  margin: 2rem;
}
:-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
::-ms-input-placeholder {
  color: #021926;
  margin: 2rem;
}
/*! CSS Used from: Embedded */
div,
span {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}

*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
/*! CSS Used from: Embedded */
span {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
</style>
