<template>
  <div id="app">
    <router-view name="header" />
    <router-view />
    <Menubar></Menubar>
    <MainFooter></MainFooter>
  </div>
</template>
<script>
import MainFooter from "./layout/MainFooter.vue";
import Menubar from "./layout/Menubar.vue";

export default {
  components: {
    MainFooter,
    Menubar
  },
};
</script>
