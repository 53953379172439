<template>
  <div
    class="flex"
    :class="[
      { 'justify-end': !left, 'justify-center md:justify-between': left }
    ]"
  >
    <div class="flex relative">
      <!-- <div class="mr-2" v-if="left">
        <img :src="image" alt="Heading icon" class="top-heading__icon" />
      </div> -->
      <h2 class="text-left mb-6 h1"><slot></slot></h2>
      <h2
        class="text-left h1"
        :class="[
          { 'background-heading': left, 'background-heading-right': !left }
        ]"
      >
        {{ background }}
      </h2>
      <!-- <div class="ml-2" v-if="!left">
        <img :src="image" alt="Heading icon" class="top-heading__icon" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: String,
    // image: String,
    left: { type: Boolean, default: false }
  }
};
</script>

<style lang="css" scoped></style>
