<template>
  <router-link
    :to="to"
    class="footer__link"
    exact
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "footer-link",
  props: {
    to: {
      type: [String, Object],
      default: undefined
    }
  }
};
</script>
