<template>
  <div class="flex sm:flex-col items-center ml-5 w-full xl:w-2/5">
    <div
      class="sm:flex hidden font-semibold text-background-heading-opaque text-20xl"
    >
      {{ background }}
    </div>
    <div class="sm:flex hidden flex-col justify-center">
      <div class="flex flex-row steps-to-estimate">
        <div class="w-48 mx-2">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="sm:hidden flex items-center mb-4">
      <div class="flex font-semibold text-background-heading-opaque text-4xl">
        {{ background }}
      </div>
      <div class="ml-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    background: String
  }
}
</script>

<style lang="css" scoped>
</style>
