<template>
  <div class="mt-3 mb-1"
  >
    <input
    :value="value"
    v-on="listeners"
    v-bind="$attrs"
    class="input input-white"
    />
    <span
      v-if="error"
      class="self-start text-primary text-xs pt-1 text-white"
      :class="{ error: error }"
      >{{ error }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    error: String,
    value: {
      type: [String, Number],
      default: ""
    },
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    }
  }
};
</script>


<style lang="css" scoped>
</style>
