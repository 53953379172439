export const COMPANY ={
  name:"CodeLane Solutions",
  contact: {
    email: 'contact@codelane.co.in',
    countryCode:"+91",
    phone: '9635335532',
    street: 'Flat-G/c, Sudha Enclave',
    landmark:"SB Gorai Rd, Pathak Bari",
    city: 'Asansol',
    country:"India",
    pin:"713301",
    website: 'codelane.co.in',
  },
  links:{
    github: 'https://github.com/CodeLane-Solutions',
    instagram:"https://www.instagram.com/codelanesolutions/",
    facebook:"https://www.facebook.com/CodeLane-Solutions-100296125853113",
    twitter:"https://twitter.com/code_lane",
    linkedin:"https://www.linkedin.com/company/codelane-soutions"
  },
  formLink:"https://script.google.com/macros/s/AKfycbzxrSyLKsf866aVtxnAC1ygzSvqrBKhAxbci6XtBYW8Rp36-uRPWLOIEQJl3GjwXvM4/exec",
  map:"https://goo.gl/maps/cLSMUqnKr3GTZm6QA"
}
